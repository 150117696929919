<template>
    <v-list-item class="text-left">
        <template v-slot:prepend>
            <v-avatar color="blue-overlay">
                <v-icon :icon="icon" :color="icon-color"></v-icon>
            </v-avatar>
        </template>
        <template v-slot:title>
            <div v-html="title" class="roboto blue-overlay-text-color"></div>
        </template>
        <template v-slot:subtitle>
            <div v-html="subtitle" class="roboto text-h6"></div>
        </template>
    </v-list-item>
</template>
<script>
    export default {
        data: () => ({
            
        }),
        props: ['icon', 'icon-color', 'title', 'subtitle']
    }
</script>