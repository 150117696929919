<template>
	<v-app class="my-0 py-0">
		<v-main class="pt-0">
			<v-app-bar color="primary" density="compact" height="48" elevation="0" class="d-none d-sm-flex">
				<template v-slot:prepend>
					<v-btn density="compact" text prepend-icon="mdi-phone" class="text-body-2">
						434.426.2120
					</v-btn>
					<v-btn density="compact" text prepend-icon="mdi-email-outline" class="text-body-2 text-lowercase">
						ues4945@gmail.com
					</v-btn>
				</template>
				<template v-slot:append>
					<v-icon icon="mdi-linkedin" color="blue-overlay" size="small"></v-icon>
					<v-icon icon="mdi-instagram" color="blue-overlay ml-4" size="small"></v-icon>
					<v-icon icon="mdi-youtube" color="blue-overlay ml-4" size="small"></v-icon>
				</template>
			</v-app-bar>
			<HeaderView></HeaderView>
			<router-view></router-view>
			<FooterView></FooterView>
		</v-main>
	</v-app>
</template>

<script>
import "./assets/css/main.css"
import FooterView from './components/common/FooterView.vue'
import HeaderView from './components/common/HeaderView.vue'

export default {
  name: 'App',
  components: {
    FooterView,
    HeaderView
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
