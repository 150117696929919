import { createApp } from 'vue'
import App from './App.vue'

//Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { createRouter, createWebHistory } from 'vue-router'

import '@mdi/font/css/materialdesignicons.css'

const primaryTheme = {
    dark: false,
    // colors: {
    //     primary: '#010079',
    //     secondary: '#FCC236',
    //     'primary-light': '#3574FD',
    //     white: '#FCC236'
    // }
    // colors: {
    //     primary: '#1D1D36',
    //     secondary: '#5C13DB',
    //     'secondary-light': '#A4A3E7',
    //     'primary-light': '#e0e0e0',
    //     'blue-overlay': '#E4F9FF',
    //     white: '#000000'
    // }
    colors: {
        primary: '#031F42',
        secondary: '#a41034',
        tertiary: '#00356b',
        'grey-darken': '#222',
        'secondary-light': '#A4A3E7',
        'primary-light': '#e0e0e0',
        'blue-overlay': '#E4F9FF',
        white: '#000000'
    }
};

const routes = [
    {
        path: '', 
        component: () => import('./components/pages/HomeView.vue')
    },
    {
        path: '/about', 
        component: () => import('./components/pages/AboutView.vue')
    },
    {
        path: '/services',
        component: () => import('./components/pages/ServicesView.vue')
    },
    {
        path: '/services/academic-advising',
        component: () => import('./components/pages/AcademicAdvisingView.vue')
    },
    {
        path: '/services/academic-program',
        component: () => import('./components/pages/AcademicProgramView.vue')
    },
    {
        path: '/services/academic-instruction',
        component: () => import('./components/pages/AcademicInstructionView.vue')
    },
    {
        path: '/contact-us',
        component: () => import('./components/pages/ContactView.vue')
    }
]

const router = createRouter({
    routes,
    history: createWebHistory()
})

const vuetify = createVuetify({
    theme: {
        defaultTheme: 'primaryTheme',
        themes: {
            primaryTheme,
        }
    },
    components, 
    directives
})

createApp(App).use(vuetify).use(router).mount('#app')
