<template>
    <div>
        <v-app-bar height="56" elevation="2" class="d-none d-sm-flex">
            <v-app-bar-title>UES</v-app-bar-title>
            <v-spacer></v-spacer>
            <TabButton v-bind:text="'Home'" :destination="'/'"></TabButton>
            <TabButton v-bind:text="'About Us'" :destination="'/about'"></TabButton>
            <DropdownButton></DropdownButton>
            <TabButton v-bind:text="'Contact Us'" :destination="'/contact-us'"></TabButton>
            <RegularButton v-bind:text="'Get Started'" v-bind:color="'secondary'" v-bind:text-color="'primary'" class="fill-height">
            </RegularButton>
        </v-app-bar>
        <v-app-bar height="56" elevation="2" class="d-sm-none d-md-none d-lg-none d-xl-none top-0">
            <v-app-bar-title>UES</v-app-bar-title>
            <v-spacer></v-spacer>
            <v-app-bar-nav-icon @click.stop="changeDrawer" :icon="icon"></v-app-bar-nav-icon>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" class="d-sm-none d-md-none d-lg-none d-xl-none top-56 h-auto">
            <v-list class="text-left px-4">
                <v-list-item title="Home" :href="'/'"></v-list-item>
                <v-list-item title="About Us" :href="'/about'"></v-list-item>
                <v-list-group value="Services">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" title="Services">
                        </v-list-item>
                    </template>
                    <v-list-item
                        v-for="([title, destination], i) in services"
                        :key="i"
                        :title="title"
                        :value="title"
                        :href="destination"
                    ></v-list-item>
                </v-list-group>
                <v-list-item title="Contact Us" :href="'/contact-us'"></v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import TabButton from './button/Tab-Button.vue'
import DropdownButton from './button/Dropdown-Button.vue'
import RegularButton from './button/Regular-Button.vue'
export default {
    name: 'HeaderView',
    components: {
        TabButton,
        RegularButton,
        DropdownButton
    },
    data() {
        return {
            drawer: false,
            icon: 'mdi-menu',
            items: [
                {
                    title: 'Home',
                    value: '/'
                },
                {
                    title: 'About Us',
                    value: '/about'
                },
                {
                    title: 'Services',
                    value: ''
                },
                {
                    title: 'Contact Us',
                    value: ''
                }
            ],
            services: [
                ['Academic Instruction', '/services/academic-instruction'],
                ['Academic Program', '/services/academic-program'],
                ['Academic Advising', '/services/academic-advising']
            ],
            watch: {
                group () {
                    this.drawer = false
                }
            }
        }
    },
    methods: {
        changeDrawer() {
            this.drawer = !this.drawer
            this.icon = this.drawer ? 'mdi-window-close' : 'mdi-menu' 
        }
    }
}
</script>