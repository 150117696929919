<template>
    <v-btn :color="color" :class="text-color" variant="flat" rounded="0" :size="size" 
    class="text-capitalize roboto-light text-subtitle-1">
        {{text}}
    </v-btn>
</template>
<script>
    export default {
        props: ['text', 'color']
    }
</script>