<template>
    <div>
        <v-footer class="bg-grey-darken text-center d-flex flex-column">
            <v-container>
                <v-row>
                    <v-col cols="12" lg="3">
                        <div class="text-h1">
                            UES
                        </div>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <two-line-list-item v-bind:icon="'mdi-phone'" v-bind:icon-color="'secondary'" 
                        v-bind:title="link1" v-bind:subtitle="value1">
                        </two-line-list-item>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <two-line-list-item v-bind:icon="'mdi-email-outline'" v-bind:icon-color="'secondary'" 
                        v-bind:title="link3" v-bind:subtitle="value3">
                        </two-line-list-item>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-col cols="12">
                        <v-list-item class="py-8">
                            <v-list-item-title class="blue-overlay-text-color roboto">
                                One-Stop Multi-Channel Support for Enrollment Management & Other Administrative Offices
                            </v-list-item-title>
                            <v-list-item-subtitle class="roboto-regular">
                                Highly-specialized customer service solutions for administrative departments of colleges and universities.
                            </v-list-item-subtitle>
                            <template v-slot:append>
                                <rounded-button v-bind:text="'Contact Us'" v-bind:color="'secondary'" 
                                v-bind:text-color="'primary--text'" v-bind:size="'large'"></rounded-button>
                            </template>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12">
                        <small>&copy; {{ new Date().getFullYear() }} — 
                            <strong>University Education Services</strong></small>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
    </div>
</template>
<script>
import RoundedButton from './button/Rounded-Button.vue';
import TwoLineListItem from './list-item/Two-Line-List-Item.vue';

    export default{
    data: () => ({
        link1: "Phone",
        link2: "Location",
        link3: "Inquiry",
        value1: "434.426.2120",
        value2: "101, Adams Drive",
        value3: "ues4945@gmail.com"
    }),
    components: { RoundedButton, TwoLineListItem }
}
</script>