<template>
    <div>
        <v-btn :color="color" :class="text-color" variant="flat" rounded class="px-6" :size="size">
            {{text}}
        </v-btn>
    </div>
</template>
<script>
    export default {
        props: ['text', 'text-color', 'color', 'size']
    }
</script>