<template>
    <v-menu open-on-hover>
        <template v-slot:activator="{ props }">
            <v-btn v-bind="props" class="roboto-regular text-capitalize text-subtitle-1" variant="text" 
            append-icon="mdi-chevron-down">
                Services
            </v-btn>
        </template>
        <v-list>
            <v-list-item v-for="(item, index) in items" :key="index" :href="item.destination">
                <v-list-item-title>
                    {{ item.title }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
    export default {
        data() {
            return {
                items: [
                    { title: 'Academic Instruction', destination: '/services/academic-instruction' },
                    { title: 'Academic Program', destination: '/services/academic-program' },
                    { title: 'Academic Advising', destination: '/services/academic-advising' }
                ]
            }
        }
    }
</script>